import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from '@firebase/firestore';
import { db } from '../firebase-config';
import Comments from '../comments/Comments';
import Comment from '../comments/Comment';

const Post = () => {
    const { blogURL } = useParams();
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getBlog = async () => {
            const blogsRef = collection(db, "posts");
            const q = query(blogsRef, where("url", "==", blogURL)); 
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setBlog(doc.data());
                setLoading(false);
            });
        }

        getBlog()
    }, [blogURL]);
    
    if (!loading) {
        document.title = 'astro_davido Astrophotography Blog - ' + blog.title;

        return (
            <>
            <div className="post">
                <img src={`../images/${blog.image}`} alt={blog.image_alt} />
    
                <h1>
                    {blog.title}
                </h1>
    
                <p className="post_date">{blog.date_created.toDate().toDateString()}</p>
    
                <p className="post_body" dangerouslySetInnerHTML={{__html: blog.body}} />
    
            </div>

            {/* <Comments blog={blog} /> */}
            {/* <Comment blog={blog}/> */}

            </>
        )
    }

}

export default Post;