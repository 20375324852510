import React, { useState } from 'react';
import { auth } from '../../firebase-config';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useNavigate } from  'react-router-dom';
import './login.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            const user = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            navigate('/admin/dashboard')
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <div className="login">
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email" 
                        onChange={(event) => { 
                            setEmail(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder="Password" 
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}/>
                </Form.Group>
                <Button variant="secondary" type="submit" onClick={onSubmit}>
                    Login
                </Button>
            </Form>
        </div>
    )       
}

export default Login;