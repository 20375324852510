import { useState, useEffect } from 'react';
import Loading from '../loading/Loading';
import { NavLink } from 'react-router-dom';
import './images.scss';

const Images = () => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    document.title = 'astro_davido Images';
    let image_ids = [];

    useEffect(() => {
        const imagesSet = (sessionStorage.getItem('images') !== null);
        if (!imagesSet) {
            setLoading(true);
            const getImages = async () => {
                await fetch('https://frozen-basin-16173-a8701ae14e3e.herokuapp.com/api/getImages')
                // await fetch('http://127.0.0.1:8000/api/getImages')
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    window.sessionStorage.setItem('images', JSON.stringify(data));
                    setImages(data);
                })
                .finally(() => {
                    setLoading(false);
                })
            }

            getImages()
        } else {
            setImages(JSON.parse(window.sessionStorage.getItem('images')));
        }
    }, []);

    if (loading) {
        return <Loading />
    }

    return (
    	<div className="images-grid-container">
        	{images.map((image, index) => {
                let dateString = new Date(image.published).toDateString();

          	    return (
                
                    <div key={image.id} className="image-col">
                        <NavLink className="image-link" to={`/images/${image.id}`} state={image}>
                            <figure className="grid-item grow">
                                <img src={`${image.url_regular}`} alt={image.title} />
                                <p className="image-title">{image.title}</p>
                                <p className="image-date">{dateString}</p>
                            </figure>
                        </NavLink> 
          	        </div>

                ) 
                
            })}

        </div>
    );
};
    
export default Images;