import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import { NavLink }
    from 'react-router-dom';
import './blog.scss';

const Blog = () => {
    const [posts, setPosts] = useState([]); 
    const postsCollectionRef = collection(db, 'posts');
    document.title = 'astro_davido Astrophotography Blog';

    useEffect(() => {

        const getPosts = async () => {
            const data = await getDocs(postsCollectionRef);
            setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }

        getPosts()     

    }, []);

    return (   
    	<div className="posts">
        	{[...posts].reverse().map((post) => { 

          	return (
                <div key={post.id} className="post">
                    <NavLink to={`/blog/${post.url}`}>
					    <img src={`../images/${post.image}`} alt={post.image_alt} />
                    </NavLink> 

            	    <h1>
					    <NavLink to={`/blog/${post.url}`}>{post.title}</NavLink>
				    </h1>

				    <p className="post_date">{post.date_created.toDate().toDateString()}</p>

            	    <p className="post_summary" dangerouslySetInnerHTML={{__html: post.summary}}></p>

          	    </div>
			  )
        })}
        </div>
    );
  };
    
  export default Blog;