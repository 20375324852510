import './NotFound.scss';

const NotFound = () => {
    return (
        <div className='not-found'>
            <a href='/'>
            <img src='../images/not-found.png' alt='404 - not found' />
            </a>
        </div>
    )
}

export default NotFound;