import { useState, useEffect } from 'react';
import { NavLink } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../images/astro_davido_logo.png';
import Affiliate from '../affiliate/Affiliate';
import { auth } from '../firebase-config';
import './navbar.scss';
import Button from 'react-bootstrap/Button';
import { signOut } from 'firebase/auth';

function NavBar() {

	const [user, setUser] = useState({}); 

	const showLogoutButton = () => {
		document.querySelector('.admin-logout-button').style.display = 'block';
	}

	const hideLogoutButton = () => {
		document.querySelector('.admin-logout-button').style.display = 'none';
	}

	const logout = () => {
		signOut(auth)
		.then(() => {
			hideLogoutButton();
		})
	}

    useEffect(() => {
        const getUser = async () => {
            const data = await auth.currentUser;
			if (data) {
				showLogoutButton();
			}
            setUser(data);
        }

        getUser()

    }, [auth.currentUser]);

	return (
		<>
    		<Navbar expand='lg'>
      			<Container>
        			<NavLink href='/' className='text-dark'>
						<img className='logo' src={Logo} alt='logo' />
					</NavLink>
        			<NavLink href='/' className='text-dark'>
						<Navbar.Brand>astro_davido astrophotography</Navbar.Brand>
					</NavLink>
        			<Navbar.Toggle aria-controls='basic-navbar-nav' />
        			<Navbar.Collapse id='basic-navbar-nav'>
          				<Nav className='me-auto' >
          					<Nav.Link href='/blog' className='text-dark'>Blog</Nav.Link>
            				<Nav.Link href='/images' className='text-dark'>Images</Nav.Link>
            				{/* <Nav.Link href="/about" className="text-dark">About Me</Nav.Link> */}
            				<Nav.Link href='/contact' className='text-dark'>Contact Me</Nav.Link>
          				</Nav>
        			</Navbar.Collapse>
					<Button className='admin-logout-button' style={{display: 'none'}} onClick={logout}>
						Logout
					</Button>
      			</Container>
    		</Navbar>
			<Affiliate />
		</>
  );
}

export default NavBar;