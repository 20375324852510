import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase-config';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import './dashboard.scss';

const Login = () => {
    const [user, setUser] = useState({}); 
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const data = await auth.currentUser;
			if (data) {
				showAdminButtons();
			} else {
                hideAdminButtons();
            }
            setUser(data);
        }

        getUser()

    }, [auth.currentUser]);

    const showAdminButtons = () => {
        document.querySelector('.dashboard').style.display = 'block';
    }

    const hideAdminButtons = () => {
        document.querySelector('.dashboard').style.display = 'none';
    }

    const toAddPost = () => {
        navigate('/admin/add-blog-post');
    };

    return (
        <div className='dashboard' style={{display: 'none'}}>
            <Button onClick={toAddPost}>Add Blog Post</Button>
        </div>
    )       
}

export default Login;