import React from 'react';
import './affiliate.scss';

const Affiliate = () => {
    return(
        <div className="affiliate_link">
            <a className="affiliate_link_hps" href="https://www.highpointscientific.com/?rfsn=6479855.934b3b3" target="_blank">
                <img src="../images/hps.png" alt="Hight Point Scientific Logo" />
            </a>
        </div>
    );
}

export default Affiliate;