import React, { useState } from 'react';
import { db } from '../../firebase-config';
import { collection, addDoc } from "firebase/firestore";
import './AddPost.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddPost = () => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [summary, setSummary] = useState('');
    const [url, setUrl] = useState('');
    const [imageFilename, setImageFilename] = useState('');
    const [imageAlt, setImageAlt] = useState('');

    const onSubmit = async (event) => {
        event.preventDefault();
        // Need to setup javascript date string to pass in
        const dbRef = collection(db, "posts");
        const current_date = new Date();
        const data = {
            title: title,
            body: body,
            summary: summary,
            url: url,
            image: imageFilename,
            image_alt: imageAlt,
            date_created: current_date,
            is_active: 1
         };

         addDoc(dbRef, data)
            .then(docRef => {
                console.log('Document has been added successfully');
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className='add-blog-post'>
            <Form>
                <Form.Group className='mb-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter title" 
                        onChange={(event) => { 
                            setTitle(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Body</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter body" 
                        onChange={(event) => {
                            setBody(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter summary" 
                        onChange={(event) => { 
                            setSummary(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter url" 
                        onChange={(event) => { 
                            setUrl(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter image filename" 
                        onChange={(event) => { 
                            setImageFilename(event.target.value)
                        }}/>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter image alt" 
                        onChange={(event) => { 
                            setImageAlt(event.target.value)
                        }}/>
                </Form.Group>

                <Button variant='secondary' type='submit' onClick={onSubmit}>
                    Add Blog Post
                </Button>
            </Form>
        </div>
    )
}

export default AddPost;