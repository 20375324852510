import Image from '../../images/contact.jpg';
import Email from '../../images/email.svg';
import Instagram from '../../images/instagram.svg'
import './contact.scss';

const Contact = () => {
    return (
      <div className="contact_us">
        <div className="image_container">
      		<img className="main_image" src={Image} alt="Astronaut staring at robot astronaut" />
        </div>
          
        <h5>Wanna chat? Contact me using one of the methods below:</h5>
		  
		<div className="contact_links">  
			<div className="contact_link">
				<a href="mailto:astro.davido84@gmail.com">
					<img src={Email} alt="Email Icon" />
					<figcaption>astro.davido84@gmail.com</figcaption>
				</a>
			</div>

			<div className="contact_link">
				<a href="https://ig.me/m/astro_davido">
					<img src={Instagram} alt="Instagram Icon" />	
				</a>
			</div>

		</div>	
		
      </div>
    );
  };
    
  export default Contact;