import './app.scss';
import NavBar from './components/navbar/NavBar';
import { useState, useEffect } from 'react'; 
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';  
import { auth } from './components/firebase-config'	;
import Images from './components/image/Images';
import Blog from './components/blog/Blog';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Post from './components/blog/Post';
import Image from './components/image/Image';
import Login from './components/admin/login/Login';
import Dashboard from './components/admin/dashboard/Dashboard';
import AddPost from './components/admin/addpost/AddPost';
import NotFound from './components/notfound/NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

	const [loggedIn, setLoggedIn] = useState(false);
  		auth.onAuthStateChanged((user) => {
    		if (user) {
      			setLoggedIn(true);
    		} else {
      			setLoggedIn(false);
    		}
  	});

  	useEffect(() => {
    	if (loggedIn != undefined) {
      	//do loggedIn stuff
	  	//Need to add logout button in header and logout function 
	  	console.log('loggedIn?');
    	} else {
			console.log('not loggedin??');
		}
  	}, [loggedIn]);

  	return (
    	<Router>
        	<NavBar />
        	<Routes>
        		<Route exact path='/' element={<Images />} />
          		<Route path='/images' element={<Images />} />
          		<Route path='/blog' element={<Blog />} />
          		<Route path='/about' element={<About />} />
          		<Route path='/contact' element={<Contact />} />
				<Route path='blog'>
   					<Route path=':blogURL' element={<Post />} />
				</Route>
      			<Route path='images'>
        			<Route path=':imageId' element={<Image />} /> 
      			</Route>
				<Route path='/admin/login' element={<Login />} />
				<Route path='/admin/dashboard' element={<Dashboard />} />
				<Route path='/admin/add-blog-post' element={<AddPost />} />
				<Route path='*' element={<NotFound />}/>
        	</Routes>
    	</Router>
  	);
}

export default App;