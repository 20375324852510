import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getDoc, doc } from '@firebase/firestore';
import { db } from '../firebase-config';

const Image = () => {
    let location = useLocation();
    let image = location.state;
    console.log(image);
        
    document.title = 'astro_davido Images - ' + image.title;

    return (
        <div className="image">
            <img src={`${image.url_hd}`} alt={image.title} />
    
            <h1>
                {image.title}
            </h1>
    
        </div>
    )

}

export default Image;